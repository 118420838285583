import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <footer className="footer bg-white">
            <nav className="bottom-nav flex flex-col md:flex-row md:gap-16 gap-6 align-start wrap md:px-16 md:py-8 px-4 py-3">
                <div className="bottom-nav__col flex-col flex  md:gap-6 gap-4 flex-1 align-start ">
                    <p className="logo fw-bold md:text-3xl text-2xl text-accent-500 ">ImperialCar</p>
                    <p className="text-justify">Firma Motoryzacja w Poznaniu <br /> Skup samochodów, osobowych, dostawczych uszkodzonych (bez OC i PT), Po wypadkowych</p>
                </div>
                <div className="bottom-nav__col flex flex-col  align-start md:gap-6 gap-4">
                    <p className="text-dark fw-bold fs-xl">O nas</p>
                    <ul className="flex flex-col gap-2 ">
                        <li><a >Wyróżniony</a></li>
                        <li><a >Współpraca</a></li>
                    </ul>
                </div>
                <div className="bottom-nav__col flex flex-col  align-start md:gap-6 gap-4">
                    <p className="text-dark fw-bold fs-xl">Wspólnota</p>
                    <ul className="flex flex-col gap-2">
                        <li><a >Wydarzenia</a></li>
                        <li><a >Blog</a></li>
                    </ul>
                </div>
                <div className="bottom-nav__col flex flex-col  align-start md:gap-6 gap-4">
                    <p className="text-dark fw-bold fs-xl">Media społecznościowe</p>
                    <ul className="flex flex-col gap-2">
                        <li><a href="https://www.instagram.com/imper1alcar_skup_aut24/" target="_blank">Instagram</a></li>
                        <li><a href="https://www.facebook.com/Imper1alCar/" target="_blank">Facebook</a></li>
                    </ul>
                </div>
            </nav>
            <div className="legal flex md:flex-row flex-col md:gap-5 gap-3 wrap p-4 md:p-6">
                <p className="text-dark fw-bold flex-1 text-sm md:text-base">©2023 ImperialCar. Wszelkie prawa zastrzeżone</p>
                <a className="text-sm md:text-base">Polityka prywatności</a>
                <a className="text-sm md:text-base">Regulamin</a>
            </div>

        </footer>
    )
}
