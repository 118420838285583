import React from 'react';
import './CarPage.css';
import useFetch from '../hooks/useFetch';
import ImageSlider from '../components/ImageSlider';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import { BuAccent } from "../components/Buttons/Buttons";

const strapiURL = 'https://api.imperialcar.pl';
const apiURL = 'https://api.imperialcar.pl/api/cars?_sort=date&pagination[pageSize]=200&populate=*';

// const strapiURL = 'http://localhost:1337';
// const apiURL = 'http://localhost:1337/api/cars?populate=*'; 

export default function CarPage() {
    const { loading, error, data } = useFetch(apiURL);
    const { id } = useParams();
    let imagesURLs = []; // here will be stored URLs of images
    let idCar = Number(id); // turninig string into number

    function isThatCar(fetchedCar) {
        return fetchedCar[1].id === idCar;
    }
    
    if (loading) return <Loader />
    if (error) return <p>Error!!!</p>

    let foundCar = data.find(isThatCar);

    foundCar[1].attributes.gallery.data.map(car => (
        imagesURLs.push(strapiURL + car.attributes.url)
    ));

    return (
        /*START*/
        <div className="content m-auto my-8 px-4 max-w-[72rem] flex flex-col gap-12">
            <div className="car grid md:grid-cols-2 md:grid-rows-1 grid-rows-[auto_auto] grid-cols-1 gap-6 justify-items-center">
                <ImageSlider id='ImageSlider' slides={imagesURLs} />
                <div className="car__details p-6 rounded-2xl flex flex-col bg-white  md:mr-auto drop-shadow-md">
                    <h2 className=" font-bold text-dark lg:text-3xl text-2xl">
                        {foundCar[1].attributes.title}
                    </h2>
                    <ReactMarkdown className="car__description tex-lg lg:mt-8 mt-4 leading-loose">
                            {foundCar[1].attributes.description}
                    </ReactMarkdown>
                    <div className="car__specs grid l xl:grid-cols-1 grid-cols-1  gap-x-8 md:gap-y-4 gap-y-4 lg:mt-auto my-4">
                        {foundCar[1].attributes.body &&
                            <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Nadwozie:</p>
                            <p>{foundCar[1].attributes.body}</p>
                        </div>
                        }
                        {foundCar[1].attributes.year &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Rok:</p>
                            <p>{foundCar[1].attributes.year}</p>
                        </div>
                        }
                        {foundCar[1].attributes.mileage &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Przebieg:</p>
                            <p>{foundCar[1].attributes.mileage}</p>
                        </div>
                        }
                        {foundCar[1].attributes.fuel &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Paliwo:</p>
                            <p>{foundCar[1].attributes.fuel}</p>
                        </div>
                        }
                        {foundCar[1].attributes.power &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Moc silnika:</p>
                            <p>{foundCar[1].attributes.power}</p>
                        </div>
                        }
                        {foundCar[1].attributes.engine_size &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Poj. silnika:</p>
                            <p>{foundCar[1].attributes.engine_size}</p>
                        </div>
                        }
                        {foundCar[1].attributes.seats &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Liczba miejsc:</p>
                            <p>{foundCar[1].attributes.seats}</p>
                        </div>
                        }
                        {foundCar[1].attributes.doors &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Liczba drzwi:</p>
                            <p>{foundCar[1].attributes.doors}</p>
                        </div>
                        }
                        {foundCar[1].attributes.gearbox &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Skrzynia biegów:</p>
                            <p>{foundCar[1].attributes.gearbox}</p>
                        </div>
                        }
                        {foundCar[1].attributes.drive &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Napęd:</p>
                            <p>{foundCar[1].attributes.drive}</p>
                        </div>
                        }
                        {foundCar[1].attributes.color &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Kolor:</p>
                            <p>{foundCar[1].attributes.color}</p>
                        </div>
                        }
                        {foundCar[1].attributes.country &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Kraj pochodzenia:</p>
                            <p>{foundCar[1].attributes.country}</p>
                        </div>
                        }
                        {foundCar[1].attributes.first_registration &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Data pierwszej rejestracji:</p>
                            <p>{foundCar[1].attributes.first_registration}</p>
                        </div>
                        }
                        {foundCar[1].attributes.vin &&
                        <div className="spec__group flex justify-between lg:text-lg text-md font-medium">
                            <p className="text-secondary-300">Vin:</p>
                            <p>{foundCar[1].attributes.vin}</p>
                        </div>
                        }
                    </div>

                    <div className="car__actions mt-auto">
                        <div className="flex md:flex-row flex-col justify-between md:items-end lg:gap-6 gap-4">
                            <div className="flex-1">
                                {foundCar[1].attributes.state==='zarezerwowane' ?
                                    (<p className="text-almost-black fw-bold fs-xl text-linethrough">{foundCar[1].attributes.price + ' PLN'} </p>):
                                    (<p className="text-almost-black fw-bold fs-xl">{foundCar[1].attributes.price + ' PLN'} </p>)
                                }
                                {foundCar[1].attributes.state==='zarezerwowane' &&
                                    <p className="fw-bold fs-regular text-red-700">
                                        ZAREZERWOWANE
                                    </p>
                                }
                                {foundCar[1].attributes.state==='soon' &&
                                    <p className="fw-bold fs-regular text-orange-500">
                                        Już wkrótce!
                                    </p>
                                }
                            </div>
                            <BuAccent
                                className=" bu-med fw-base flex-1 rounded-sm py-8"
                                text="Zadzwoń"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        /*FINISH*/
    )
}