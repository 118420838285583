import { useState, useEffect } from 'react';
import '../components/ImageSlider.css';

export default function ImageSlider(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isGalleryDisplayed, setIsGalleryDisplayed] = useState(false);

    const ImageSlideStyle = {
        backgroundImage: `url(${props.slides[currentIndex]})`
    }

    const goToPrevImg = () => {
        const newIndex = (currentIndex === 0) ? props.slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const goToNextImg = () => {
        const newIndex = (currentIndex === props.slides.length - 1) ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    const dotsStyle = {
        fontSize: '60px',
        lineHeight: '47px',
        marginTop: "-12px"
    }

    // Auto sliding
    useEffect(() => {
        const interval = setInterval(() => {
            goToNextImg();
        }, 7000);
      
        return () => {
          clearInterval(interval);
        };
      }, [currentIndex]); // goToNextImg changes currentIndex invoking this effect which invokes goToNextImg making loop.

    const [activePic, setActivePic] = useState(0);
    const CARPICS = props.slides;
    const ActiveBorder = (index) => {
        return index === activePic ? " border-2 border-accent-500 " : "";
    };

    return (

        <div className="car__pictures grid grid-cols-3 gap-4 grid-rows-4  self-start md:ml-auto">
            <img
                className="block m-auto col-span-3 row-span-3 rounded-2xl border border-secondary-200"
                src={CARPICS[activePic]}
            ></img>
            <img
                className={"rounded-2xl" + ActiveBorder(0)}
                src={CARPICS[0]}
                onClick={() => {
                    setActivePic(0);
                }}
            ></img>
            {CARPICS[1] &&
            <img
                className={"rounded-2xl" + ActiveBorder(1)}
                src={CARPICS[1]}
                onClick={() => {
                    setActivePic(1);
                }}
            ></img>
             }
            {CARPICS[2] &&
            <img
                className={"rounded-2xl" + ActiveBorder(2)}
                src={CARPICS[2]}
                onClick={() => {
                    setActivePic(2);
                }}
            ></img>
            }
            {CARPICS[3] &&
            <img
                className={"rounded-2xl" + ActiveBorder(3)}
                src={CARPICS[3]}
                onClick={() => {
                    setActivePic(3);
                }}
            ></img>
            }
            {CARPICS[4] &&
            <img
                className={"rounded-2xl" + ActiveBorder(4)}
                src={CARPICS[4]}
                onClick={() => {
                    setActivePic(4);
                }}
            ></img>
            }
            {CARPICS[5] &&
            <img
                className={"rounded-2xl" + ActiveBorder(5)}
                src={CARPICS[5]}
                onClick={() => {
                    setActivePic(5);
                }}
            ></img>
            }
            {CARPICS[6] &&
            <img
                className={"rounded-2xl" + ActiveBorder(6)}
                src={CARPICS[6]}
                onClick={() => {
                    setActivePic(6);
                }}
            ></img>
            }
        </div>
    )
}