import './Thanks.css';

export default function Thanks() {

    return (
        <div id="Thanks">
            <div>Dziękujemy za wypełnienie formularza.</div>
            <div>Wkrótce skontaktujemy się z Tobą.</div>
        </div>
    )
}