import React, { useState, useEffect, useRef } from 'react';
import '../components/Wallpaper.css';
import HeroForm from "../components/Forms/HeroForm";
import ImgHero from "../assets/hero.png";

export default function Wallpaper() {
    const [background1, setBackground1] = useState(1);
    const [background2, setBackground2] = useState(2);
    const [shouldUpdateBackground1, setShouldUpdateBackground1] = useState(true);

    let styling1 = {
        backgroundImage: `url(${require('../assets/backgrounds/wallpaper' + background1 + '.webp')})`
    }

    let styling2 = {
        backgroundImage: `url(${require('../assets/backgrounds/wallpaper' + background2 + '.webp')})`
    }

    useEffect(() => {
        const interval = setInterval(() => {
            console.log("conditional " + shouldUpdateBackground1);
            if (shouldUpdateBackground1) {
                setBackground1(prevValue => {
                    if (prevValue <  4) {
                        return prevValue + 2;
                    } else {
                        return prevValue%3;
                    }
                });
            } else {
                setBackground2(prevValue => {
                    if (prevValue <  4) {
                        return prevValue + 2;
                    } else {
                        return prevValue%3;
                    }
                });
            }
            setShouldUpdateBackground1(prevValue => !prevValue);
        }, 5000);

        return () => clearInterval(interval);
    }, [shouldUpdateBackground1]);

    return (

        <section
            className="hero
       grid grid-cols-1 grid-rows-3 content-center gap-4 m-auto my-8 max-w-[100rem]
       md:grid-rows-2 md:grid-cols-2 md:gap-8 md:mt-12
       lg:my-16
      "
        >
            <div
                className="
        text-center hero-text max-w-2xl self-center row-start-2
        md:text-start
        lg:row-start-1
        "
            >
                <h1
                    className=" font-bold text-4xl text-dark leading-relaxed
          xl:text-6xl
          "
                >
                    Witamy w ImperialCar
                </h1>
                <p className=" text-xl mt-4 leading-loose">
                    Twoim miejscu do zakupu wysokiej jakości samochodów używanych! Jesteśmy liderem w branży i oferujemy szeroki wybór sprawdzonych, niezawodnych pojazdów, które spełnią wszystkie Twoje potrzeby.
                </p>
            </div>
            <img
                src={ImgHero}
                alt=""
                className="
          self-center max-w-full max-h-full object-cover row-start-1 col-start-1 col-span-2
          lg:row-span-2 lg:col-start-2 lg:col-span-1"
            ></img>
            <HeroForm
                className="
        self-center row-start-3 max-w-2xl
        md:row-start-2
        "
            ></HeroForm>
        </section>
    )
}