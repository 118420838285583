import React from 'react';
import Car from '../components/Car';
import Loader from '../components/Loader';
import mergeSort from '../hooks/mergeSort';
import './Pages.css';
// import { useQuery, gql } from '@apollo/client';
import useFetch from '../hooks/useFetch';
import Wallpaper from "../components/Wallpaper";

const strapiURL = 'https://api.imperialcar.pl';
const apiURL = 'https://api.imperialcar.pl/api/cars?_sort=date:DESC&pagination[pageSize]=200&populate=*';

// Local version:
// const strapiURL = 'http://localhost:1337';
// const apiURL = 'http://localhost:1337/api/cars?populate=*';

export default function Offer() {
    const { loading, error, data } = useFetch(apiURL);
    const wallpaper = <Wallpaper />;
    const element = <h1>{wallpaper}</h1>;

    if (loading) return <Loader/>
    if (error) return <p>Error!!!</p>

    const filteredData = data.filter(car => car[1].attributes.state !== 'Sprzedane');
    const sortedFilteredData = mergeSort(filteredData);

    return (
        <section className="flex flex-col gap-4 mt-6">
            {element}
            <div className="flex flex-row justify-between">
                <p className="text-light">SPRAWDŹ NASZĄ OFERTĘ</p>
                <a href="#" className="fw-semibold">
                    Pokaż wszystkie
                </a>
            </div>
            <div className="grid grid-cols-[repeat(auto-fit,_minmax(18rem,_1fr))] gap-6">
                {filteredData.map((car, index) => (
                        <Car
                            key={'Car no ' + index}
                            id={car[1].id}
                            state={car[1].attributes.state}
                            title={car[1].attributes.title}
                            gearbox={car[1].attributes.Gearbox}
                            mileage={car[1].attributes.mileage}
                            year={car[1].attributes.year}
                            price={car[1].attributes.price}
                            oldprice={car[1].attributes.oldprice}
                            fuel={car[1].attributes.fuel}
                            seats={car[1].attributes.seats}
                            power={car[1].attributes.power}
                            imageSource={strapiURL + car[1].attributes.gallery.data[0].attributes.url}
                        />
                    )
                )}
            </div>
        </section>
    )
}