import React from 'react';
import Car from '../components/Car';
import Loader from '../components/Loader';
import useFetch from '../hooks/useFetch';
import mergeSort from '../hooks/mergeSort';
import './Pages.css';

const strapiURL = 'https://api.imperialcar.pl';
const apiURL = 'https://api.imperialcar.pl/api/cars?_sort=date:DESC&pagination[pageSize]=200&populate=*';

// const strapiURL = 'http://localhost:1337';
// const apiURL = 'http://localhost:1337/api/cars?populate=*';

export default function Sold() {
    const { loading, error, data } = useFetch(apiURL);
	
    if (loading) return <Loader/>
    if (error) return <p>Error!!!</p>

    const filteredData = data.filter(car => car[1].attributes.state === 'Sprzedane')

    // Using merge sort for setting cars in the order basing on "owners_number" attribute. It's treated as "kolejność" atttribute in the user's panel
    const sortedFilteredData = mergeSort(filteredData);

    return (
      <section className="flex flex-col gap-4 mt-6">
        <div className="flex flex-row justify-between">
          <p className="text-light">POJAZDY, KTÓRE ZNALAZŁY JUŻ NOWEGO WŁAŚCICIELA</p>
        </div>
        <div className="grid grid-cols-[repeat(auto-fit,_minmax(18rem,_1fr))] gap-6">
                {sortedFilteredData.map((car, index) => (
                  <Car
                    key={'Car no ' + index}
                    id={car[1].id}
                    state={car[1].attributes.state}
                    title={car[1].attributes.title}
                    gearbox={car[1].attributes.Gearbox}
                    mileage={car[1].attributes.mileage}
                    year={car[1].attributes.year}
                    price={car[1].attributes.price}
                    oldprice={car[1].attributes.oldprice}
                    fuel={car[1].attributes.fuel}
                    seats={car[1].attributes.seats}
                    power={car[1].attributes.power}
                    imageSource={strapiURL + car[1].attributes.gallery.data[0].attributes.url}
                  />
                    )
                )}
        </div>
      </section>
    )
}
