import React from 'react';
import './Pages.css';

export default function Buy() {
    return (
        <div id="Buy">
            <div id="Buy__area">
                {/* enctype has been set for sending images */}
                    <form action="https://formsubmit.co/drivesmall@gmail.com" method="POST" encType="multipart/form-data" class="grid md:grid-cols-5 w-full max-w-6xl m-auto p-4 gap-4">
                        <div class="md:col-span-3 col-start-1 self-start grid lg:grid-cols-2 sm:p-8 p-6 bg-white gap-6 rounded-xl">
                              <div class="heading lg:col-span-2">
                                  <h1 class="text-dark text-xl font-bold">Formularz odkupu</h1>
                                  <p class="text-secondary-300 mt-1">Jeżeli są Państwo zainteresowani sprzedażą swojego samochodu, prosimy o kontakt telefoniczny lub wypełnienie i przesłanie poniższego formularza z uwzględnieniem podstawowych danych pojazdu, własnym opisem oraz poglądowymi zdjęciami.</p>
                               </div>
                               <div>
                                    <p class="font-semibold text-dark mb-2">Imię</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Imię" placeholder="John"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Nazwisko</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Imię" placeholder="Doe"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Numer telefonu</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Numer telefonu" placeholder="+44XXXXXXXXX"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Adres e-mail</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Adres e-mail" placeholder="Adres e-mail"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Proponowana cena</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Cena" placeholder="5000"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Marka i model</p>
                                    <div class=" undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Marka i model" placeholder="Ford Mondeo"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Rok produkcji</p>
                                    <div class="undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Rok produkcji" placeholder="2018"/>
                                    </div>
                                </div>
                                <div>
                                    <p class="font-semibold text-dark mb-2">Przebieg (km)</p>
                                    <div class="undefined flex flex-col w-full">
                                        <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Przebieg" placeholder="10000"/>
                                    </div>
                                </div>
                            <div>
                                <p class="font-semibold text-dark mb-2">Kraj pochodzenia</p>
                                <div class="undefined flex flex-col w-full">
                                    <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Kraj" placeholder="Polska"/>
                                </div>
                            </div>
                        </div>

                        <div id='third-span' class="md:col-span-2 row-span-2 self-start md:col-start-4 col-start-1 summary bg-white grid md:grid-cols-1 rounded-xl py-6 px-4 sm:p-8 gap-y-1">
                          <div>
                            <p class="font-semibold text-dark mb-2">Wiadomość</p>
                            <div class=" undefined flex flex-col w-full">
                              <textarea type="text" name="Wiadomość" placeholder='Własny opis pojazdu/komentarz/uwag' required rows='4' class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" />
                            </div>
                          </div>
                            <span id='third-span__attachments'>
                                <input type="file" name="Załącznik 1" accept="image/png, image/jpeg"/>
                                <input type="file" name="Załącznik 2" accept="image/png, image/jpeg"/>
                                <input type="file" name="Załącznik 3" accept="image/png, image/jpeg"/>
                            </span>

                            {/* Button replaced with <input> for Safari browser */}
                            <button id='Buy__area__button' type='submit' class="button bu-accent flex gap-2  items-center justify-center  mt-2 py-4 px-6 rounded-lg mr-auto">WYŚLIJ</button>
                        </div>

                        <input type="hidden" name="_subject" value="Oferta od klienta"></input>
                        <input type="hidden" name="_next" value="https://cisiamtrovati.it/thanks"></input>
                    </form>
            </div>
        </div>
    )
}
