import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './SiteHeader.css';
import "./Navigation.css";
import getIcon from "../Helpers/IconsHelper";
import { BuAccent, BuMinimal, BuSecondary } from "./Buttons/Buttons";

export default function SiteHeader() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchVisible,setSearchVisible] = useState(false);
    const toggleSearch = (prevstate) =>{
        setSearchVisible(!prevstate);
    }

    let menuRef = useRef();
    let buttonRef = useRef();

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    function hideMenu() {
        setMenuOpen(false);
    }

    
    return (
        <nav className='top-nav md:px-16 md:py-8 px-4 py-3 bg-white flex items-center gap-3 md:gap-5 border-b border-b-secondary-200'>
            <BuSecondary className=' p-2 rounded-md md:hidden flex' LeftIcon={getIcon("search")} onClick={()=>{toggleSearch(searchVisible)}} />

            <NavLink to='/' className="logo-header" style={{color: 'black'}}>
                <p className='logo fw-bold md:text-3xl text-2xl text-accent'><img src='/imperiallogo.png' /></p>
            </NavLink>
            <ul
                className={`SiteHeader__navbar ${menuOpen ?
                    'SiteHeader__navbar--shown' : 'SiteHeader__navbar--hidden'}`}
                ref={menuRef}
            >
                <NavLink onClick={hideMenu} to='/' ><li><span>Główna</span></li></NavLink>
                <NavLink onClick={hideMenu} to='/About' > <li><span>O nas</span></li></NavLink>
                <NavLink onClick={hideMenu} to='/Buy' > <li><span>Chce sprzedać</span></li></NavLink>
                <NavLink onClick={hideMenu} to='/Sold' > <li><span>Sprzedane</span></li></NavLink>
                <NavLink onClick={hideMenu} to='/Financing' > <li><span>Jak kupić</span></li></NavLink>
                <NavLink onClick={hideMenu} to='/Contact' > <li><span>Kontakt</span></li></NavLink>
            </ul>
            <div data-visible={searchVisible} className='search-wrapper bg-white px-4 py-3 md:p-0 w-full flex gap-4 items-center justify-start fixed -top-full right-0 md:static'>
                <div className='min-w-0 md:flex flex-1 md:flex-none'>
                    <form className='search-form flex gap-2 bg-white w-f border border-secondary-100 py-2 px-4 rounded-xl min-w-0 md:flex flex-1 md:flex-none'>
                        <BuMinimal className="search-form__btsearch"  LeftIcon={getIcon("search")}>
                        </BuMinimal>
                        <input type="text" className=" focus:outline-none" placeholder="Wpisz model, wersję lub inne szczegóły" />
                    </form>
                </div>
                <BuMinimal LeftIcon={getIcon("close")} className='h-4 w-4 md:hidden flex' onClick={()=>{toggleSearch(searchVisible)}}/>
            </div>
            <ul className='top-nav__buttons flex gap-3 md:gap-5 ml-auto items-center'>
                <li><BuMinimal className='p-1 md:w-8  rounded-full' LeftIcon={getIcon("heart")}/></li>
                <li id='SiteHeader__menuBtn'
				onClick={toggleMenu} 
				ref={buttonRef}
				><BuMinimal className = 'p-1 nav-buttons__notification  rounded-full' LeftIcon={getIcon('burger')}/></li>
            </ul>

        </nav>
    )
}