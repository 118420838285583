import React from 'react';
import { NavLink } from 'react-router-dom';    
import './Car.css';
import getIcon from "../Helpers/IconsHelper";
import { BuAccent, BuMinimal } from "./Buttons/Buttons";

export default function Car(props) {

    const FuelIcon = getIcon("fuel");
    const TransitionIcon = getIcon("transition");
    const PersonsIcon = getIcon("persons");

    return (
        <div className="catalogue-card flex flex-col gap-6 rounded-xl md:p-5 p-4 bg-white h-96 w-80 ">
            <div className="flex flex-row justify-between items-start">
                <div>
                    <p className="text-almost-black fw-bold fs-xl">{props.title}</p>
                    <p className="fw-bold fs-sm text-light">{props.year}</p>
                    <span className={ (props.state === 'soon' || props.state === 'zarezerwowane') ? 'container-ribbon--yellow' : (props.state === 'Sprzedane' ? 'container-ribbon--red' : 'container-ribbon--green')}>
                        <span className={(props.state === 'soon' || props.state === 'zarezerwowane') ? 'ribbon--yellow' : (props.state === 'Sprzedane' ? 'ribbon--red' : 'ribbon--green')}>
                            {props.state === 'soon' ? 'Już wkrótce!' : (props.state === 'Sprzedane' ? 'Sprzedane' : props.state === 'zarezerwowane' ? 'Zarezerwowane' : props.state)}
                        </span>
                    </span>
                </div>
                <BuMinimal
                    className="bu-square "
                    RightIcon={getIcon("heart")}
                />
            </div>
            <div className="flex flex-col gap-4 items-center flex-1">
                <img onMouseDown={(e)=>{e.preventDefault()}} className="catalogue-card__pircture flex-1 my-4  w-56 object-contain " src={props.imageSource} alt='Solidne auta używane' />
                <div className="catalogue-card__specs flex flex-row gap-4 justify-between text-light">
                    <div className="spec-group flex flex-row gap-2 items-end">
                        <FuelIcon />
                        <p className="fs-sm fw-medium ">{props.fuel}</p>
                    </div>
                    <div className="spec-group flex flex-row gap-2 items-end">
                        <TransitionIcon />
                        <p className="fs-sm fw-medium ">{props.gearbox}</p>
                    </div>
                    <div className="spec-group flex flex-row gap-2 items-end">
                        <PersonsIcon />
                        <p className="fs-sm fw-medium ">{props.seats}</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-row justify-between items-end gap-6">
                <div className="flex-1">
                    <p className="text-almost-black fw-bold fs-xl">
                        {props.price} PLN
                    </p>
                    {props.price>100000000 && (
                        <p className="fw-bold fs-regular text-linethrough text-light">
                           old price
                        </p>
                    )}
                </div>

                <NavLink to={`/CarPage/${props.id}`} >
                    <BuAccent
                    className=" bu-med fw-base flex-1 rounded-sm py-8"
                    text="SZCZEGÓŁY"
                    />
                </NavLink>
            </div>
        </div>

    )
}