import React from 'react';
import './Pages.css';

export default function Contact() {

    return (
        <div id="Contact">
        <div>
            {/* enctype has been set for sending images */}
            <form action="https://formsubmit.co/drivesmall@gmail.com" method="POST" encType="multipart/form-data" class="grid md:grid-cols-5 w-full max-w-6xl m-auto p-4 gap-4">
                <div class="md:col-span-3 col-start-1 self-start grid lg:grid-cols-2 sm:p-8 p-6 bg-white gap-6 rounded-xl">
                    <div class="heading lg:col-span-2">
                        <h1 class="text-dark text-xl font-bold">Formularz kontaktu</h1>
                        <p class="text-secondary-300 mt-1">Jeżeli masz jakiekolwiek pytania dotyczące naszych samochodów na sprzedaż, chciałbyś umówić się na jazdę próbną lub skonsultować się z naszym zespołem, skontaktuj się z nami za pośre</p>
                    </div>
                    <div>
                        <p class="font-semibold text-dark mb-2">Imię</p>
                        <div class=" undefined flex flex-col w-full">
                            <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Imię" placeholder="John" required/>
                        </div>
                    </div>
                    <div>
                        <p class="font-semibold text-dark mb-2">Nazwisko</p>
                        <div class=" undefined flex flex-col w-full">
                            <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Nazwisko" placeholder="Doe"/>
                        </div>
                    </div>
                    <div>
                        <p class="font-semibold text-dark mb-2">Numer telefonu</p>
                        <div class=" undefined flex flex-col w-full">
                            <input type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Numer telefonu" placeholder="+44XXXXXXXXX" required/>
                        </div>
                    </div>
                    <div>
                        <p class="font-semibold text-dark mb-2">Wiadomość</p>
                        <div class=" undefined flex flex-col w-full">
                            <textarea type="text" class="undefined bg-gray-100 outline-none py-3 px-4 rounded-lg w-full border-none" name="Wiadomość" placeholder='Treść' required rows='2' />
                        </div>
                    </div>
                    <button id='Buy__area__button' type='submit' class="button bu-accent flex gap-2  items-center justify-center  undefined flex flex-col w-full">WYŚLIJ</button>

                    <input type="hidden" name="_subject" value="Klient przysłał zapytanie"></input>
                    <input type="hidden" name="_next" value="https://cisiamtrovati.it/thanks"></input>
                </div>
                <div class="md:col-span-2 row-span-2 self-start md:col-start-4 col-start-1 summary bg-white grid md:grid-cols-1 rounded-xl py-6 px-4 sm:p-8 gap-y-1">
                    <h1 class="text-dark text-xl font-bold col-span-3">Informacje kontaktowe</h1>
                    <div>
                        <div>
                            <p class="font-semibold text-dark mb-2">Telefon:</p>
                            <div class=" undefined flex flex-col w-full">
                                <a href="tel:+48729353413">+48 729 353 413</a>
                            </div>
                        </div>
                        <div>
                            <p class="font-semibold text-dark mb-2">E-mail:</p>
                            <div class=" undefined flex flex-col w-full">
                                <a href="mailto:wolczynska.czesci@gmail.com">wolczynska.czesci@gmail.com</a>
                            </div>
                        </div>
                        <iframe title='Map' src="https://www.google.com/maps/embed/v1/place?q=Wołczyńska+50,+60-167+Poznań,+Polska&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" className="MapContact"></iframe>
                    </div>
                </div>
            </form>

        </div>
        </div>
    )
}