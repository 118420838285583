import React from 'react';
import './Pages.css';
import { NavLink } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import mergeSort from '../hooks/mergeSort';
import Loader from '../components/Loader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

const apiURL = 'https://api.imperialcar.pl/api/page';
export default function About() {
    const { loading, error, data } = useFetch(apiURL);
    let idPage = Number(1);

    function isThatPage(fetchedPage) {
        return fetchedPage[1].id === idPage;
    }

    if (loading) return <Loader />
    if (error) return <p>Error!!!</p>

    let foundPage = data.find(isThatPage);
    const descrip = foundPage[1].attributes.description;

    return (
        <div id="About" className="reviews p-6 grid gap-8 rounded-xl bg-white">
            <h1>{foundPage[1].attributes.title}</h1>

            <ReactMarkdown remarkPlugins={[remarkGfm]} className='text-dark mb-2'>{descrip.replace(/\n/gi, "&nbsp; \n")}</ReactMarkdown>
        </div>
    )
}