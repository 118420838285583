import React from 'react';
import { NavLink } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import mergeSort from '../hooks/mergeSort';
import Loader from '../components/Loader';
import ReactMarkdown from 'react-markdown';
import './Pages.css';

const apiURL = 'https://api.imperialcar.pl/api/page';
export default function Financing() {
    const { loading, error, data } = useFetch(apiURL);
    let idPage = Number(3);

    function isThatPage(fetchedPage) {
        return fetchedPage[1].id === idPage;
    }

    if (loading) return <Loader />
    if (error) return <p>Error!!!</p>

    let foundPage = data.find(isThatPage);

    return (
        <div id="About" className="reviews p-6 grid gap-8 rounded-xl bg-white">
            <h1>{foundPage[1].attributes.title}</h1>

            <ReactMarkdown className='About__box'>
                {foundPage[1].attributes.description}
            </ReactMarkdown>
        </div>
    )
}